import * as React from 'react';

const MenuIcon = ({ showMenu }: { showMenu: boolean }) => (
    <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        {showMenu ? (
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                <g transform="translate(4, 4)" stroke="currentColor" strokeWidth="2">
                    <path d="M0,0 L12,12" />
                    <path d="M0,12 L12,0" />
                </g>
            </g>
        ) : (
            <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
            />
        )}
    </svg>
);
export default MenuIcon;
